export const FirebaseConfig = {
	"projectId": "koteride-3f8b2",
	"appId": "1:711386714279:web:9af906cb3420b22dfb4b82",
	"databaseURL": "https://koteride-3f8b2-default-rtdb.firebaseio.com",
	"storageBucket": "koteride-3f8b2.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBMa3sr8wDAfpdU0jetvNqrR1RlDNAOaBs",
	"authDomain": "koteride-3f8b2.firebaseapp.com",
	"messagingSenderId": "711386714279",
	"measurementId": "G-Y876Q8RDFX"
};